import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Line } from 'rc-progress';
import { DurationDisplay } from './DurationDisplay';
import { gettext } from '../../../../../core/utils/text';
import styles from './WorkOrderHeader.scss';

interface TimeTracker {
    id: number;
    start_time: string;
    end_time: string | null;
    work_order: number;
}

type TimeTrackersDurationDisplayProps = {
    timeTrackers: TimeTracker[];
    plannedDuration: number;
    onlyProgress?: boolean;
};

export const TimeTrackersTimeRemaining: React.FC<TimeTrackersDurationDisplayProps> = ({
    timeTrackers,
    plannedDuration,
    onlyProgress = false,
}) => {
    const [totalDuration, setTotalDuration] = useState<moment.Duration>(
        moment.duration(0)
    );

    useEffect(() => {
        const interval = setInterval(() => {
            const calculatedDuration = timeTrackers.reduce(
                (acc, timeTracker) => {
                    const startTime = moment(timeTracker.start_time);
                    const endTime = timeTracker.end_time
                        ? moment(timeTracker.end_time)
                        : moment();
                    const duration = moment.duration(endTime.diff(startTime));
                    return acc.add(duration);
                },
                moment.duration(0)
            );
            setTotalDuration(calculatedDuration);
        }, 1000);
        return () => clearInterval(interval);
    }, [timeTrackers]);

    const percentage = (totalDuration.asMinutes() / plannedDuration) * 100;

    if (timeTrackers.length === 0) {
        return null;
    }
    const remaining = plannedDuration - totalDuration.asMinutes();
    return (
        <table>
            <tbody>
                {!onlyProgress && (
                    <tr>
                        <td>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        {remaining > 0
                                            ? gettext('Time remaining')
                                            : gettext('Time exceeding')}
                                    </Tooltip>
                                }
                            >
                                <div>
                                    <DurationDisplay
                                        minutes={
                                            plannedDuration -
                                            totalDuration.asMinutes()
                                        }
                                    />
                                </div>
                            </OverlayTrigger>
                        </td>
                    </tr>
                )}
                <tr>
                    <td className={styles.progressBar}>
                        <Line
                            percent={percentage}
                            strokeWidth={4}
                            strokeColor={remaining > 0 ? '#D3D3D3' : '#dd6e42'}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default TimeTrackersTimeRemaining;
