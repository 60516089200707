import momentPropTypes from 'react-moment-proptypes';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clsx } from 'clsx';
import moment from 'moment';

import { gettext } from 'core/utils/text';
import { workOrderDenormalizedPropType } from 'parts/workplan/propTypes';
import { PartProductPropType } from 'parts/propTypes';
import { timeTrackersSelectors } from 'parts/operations/ducks/timeTrackers';
import { WORK_ORDER_STATUS } from 'parts/workplan/constants';
import { workOrdersSelectors } from 'parts/workplan/ducks/workOrders';
import {
    downtimePropType,
    timeTrackerPropType,
} from 'parts/operations/propTypes';
import PartProductComment from 'parts/components/PartProductComment';

import {
    FaArrowDown,
    FaArrowUp,
    FaCheckCircle,
    FaExclamationCircle,
    FaPauseCircle,
    FaPlayCircle,
} from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
    AffixDowntimeButton,
    PauseButton,
    SolveDowntimeButton,
    StartButton,
    StopButton,
} from './WorkOrderButtons';
import styles from './WorkOrderHeader.scss';
import TimeTrackersDurationDisplay from './TimeTrackersDurationDisplay';
import { downtimesSelectors } from '../../../ducks/downtimes';
import TimeTrackersTimeRemaining from './TimeTrackersTimeRemaining';
import DownTimeDisplay from './DownTimeDisplay';
import { DurationDisplay } from './DurationDisplay';

const WorkOrderHeaderTables = ({
    inExpanded,
    workOrder,
    partProduct,
    isExpanded: _isExpanded,
    // Redux
    workOrderStatus: _workOrderStatus,
    timeTrackers,
    newestTracker: _newestTracker,
    oldestTracker: _oldestTracker,
    latestDowntime,
    allDownTimes,
    isNotStarted,
    isRunning: _isRunning,
    isFinished,
    isPaused,
    isDowntime,
    oldestStartTime,
    newestStartTime,
    latestPauseTime,
    notFinishedBeforeDeadline,
    startedAfterDeadline,
    notStartedBeforeDeadline,
}) => {
    if (inExpanded && isNotStarted) {
        return null;
    }
    return (
        <div className={styles.tablesSection}>
            {isFinished || isNotStarted || inExpanded ? (
                <div className={styles.rightBorder}>
                    <table>
                        <tbody>
                            {!inExpanded ||
                            isFinished ||
                            isNotStarted ? null : (
                                <>
                                    <tr>
                                        <td>{gettext('Client')}:</td>

                                        <td>{partProduct.client.name}</td>
                                    </tr>
                                    <tr>
                                        <td>{gettext('Order')}:</td>
                                        <td>{partProduct.order_number}</td>
                                    </tr>
                                </>
                            )}
                            <tr>
                                <td>{gettext('Worker')}:</td>
                                <td>
                                    {DJ_CONST.WORKERS_MAP
                                        ? DJ_CONST.WORKERS_MAP[workOrder.worker]
                                        : workOrder.worker}
                                </td>
                            </tr>
                            <tr>
                                <th>{gettext('Order deadline')}:</th>
                                <th>
                                    {notFinishedBeforeDeadline &&
                                    isNotStarted ? (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    {gettext('Past deadline')}
                                                </Tooltip>
                                            }
                                        >
                                            <span className={styles.warning}>
                                                {moment(
                                                    workOrder.end_time,
                                                ).format('DD.MM.YYYY HH:mm')}
                                            </span>
                                        </OverlayTrigger>
                                    ) : (
                                        <span>
                                            {moment(workOrder.end_time).format(
                                                'DD.MM.YYYY HH:mm',
                                            )}
                                        </span>
                                    )}
                                </th>
                            </tr>
                            {newestStartTime ? (
                                <>
                                    <tr>
                                        <td>{gettext('Actual start time')}:</td>
                                        <td>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        <div>
                                                            {gettext(
                                                                'Planned start time',
                                                            )}
                                                            <br />
                                                            {moment(
                                                                workOrder.start_time,
                                                            ).format(
                                                                'DD.MM.YYYY HH:mm',
                                                            )}
                                                        </div>

                                                        {startedAfterDeadline && (
                                                            <span>
                                                                {gettext(
                                                                    'Started late',
                                                                )}
                                                            </span>
                                                        )}
                                                    </Tooltip>
                                                }
                                            >
                                                <span
                                                    className={clsx({
                                                        [styles.warning]: startedAfterDeadline,
                                                    })}
                                                >
                                                    {oldestStartTime?.format(
                                                        'DD.MM.YYYY HH:mm',
                                                    )}
                                                </span>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>{gettext('Latest start')}:</td>
                                        <td>
                                            {newestStartTime?.format(
                                                'DD.MM.YYYY HH:mm',
                                            )}
                                        </td>
                                    </tr>
                                </>
                            ) : (
                                <tr>
                                    <td>{gettext('Planned start time')}:</td>
                                    <td>
                                        {notStartedBeforeDeadline ? (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        <div>
                                                            {gettext(
                                                                'Started late',
                                                            )}
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span
                                                    className={styles.warning}
                                                >
                                                    {moment(
                                                        workOrder.start_time,
                                                    ).format(
                                                        'DD.MM.YYYY HH:mm',
                                                    )}
                                                </span>
                                            </OverlayTrigger>
                                        ) : (
                                            <span>
                                                {moment(
                                                    workOrder.start_time,
                                                ).format('DD.MM.YYYY HH:mm')}
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            )}
                            {latestPauseTime ? (
                                <tr>
                                    <td>
                                        {isFinished
                                            ? gettext('Finish time')
                                            : gettext('Last pause time')}
                                        :
                                    </td>
                                    <td>
                                        {isFinished ? (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        <div>
                                                            {notFinishedBeforeDeadline
                                                                ? gettext(
                                                                      'Late finished by',
                                                                  )
                                                                : gettext(
                                                                      'Finished early by',
                                                                  )}
                                                            <DurationDisplay
                                                                minutes={moment
                                                                    .duration(
                                                                        moment(
                                                                            workOrder.end_time,
                                                                        ).diff(
                                                                            latestPauseTime,
                                                                        ),
                                                                    )
                                                                    .asMinutes()}
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <span
                                                    className={clsx({
                                                        [styles.warning]: notFinishedBeforeDeadline,
                                                    })}
                                                >
                                                    {latestPauseTime?.format(
                                                        'DD.MM.YYYY HH:mm',
                                                    )}
                                                </span>
                                            </OverlayTrigger>
                                        ) : (
                                            <span>
                                                {latestPauseTime?.format(
                                                    'DD.MM.YYYY HH:mm',
                                                )}
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>
                </div>
            ) : null}

            {inExpanded ? null : (
                <div
                    className={clsx(styles.timeCol, {
                        [styles.rightBorder]:
                            (isFinished || inExpanded) &&
                            allDownTimes.length > 0,
                    })}
                >
                    <table>
                        <tbody>
                            {timeTrackers.length > 0 ? (
                                <TimeTrackersDurationDisplay
                                    timeTrackers={timeTrackers}
                                    plannedDuration={workOrder.duration}
                                    showTimeRemaining={isFinished}
                                >
                                    <>
                                        {!isFinished ? (
                                            <tr>
                                                <th>
                                                    {gettext('Order deadline')}:
                                                </th>
                                                <th>
                                                    {notFinishedBeforeDeadline ? (
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    {gettext(
                                                                        'Past deadline',
                                                                    )}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles.warning
                                                                }
                                                            >
                                                                {moment(
                                                                    workOrder.end_time,
                                                                ).format(
                                                                    'DD.MM.YYYY HH:mm',
                                                                )}
                                                            </span>
                                                        </OverlayTrigger>
                                                    ) : (
                                                        <span>
                                                            {moment(
                                                                workOrder.end_time,
                                                            ).format(
                                                                'DD.MM.YYYY HH:mm',
                                                            )}
                                                        </span>
                                                    )}
                                                </th>
                                            </tr>
                                        ) : null}
                                        {latestPauseTime &&
                                        !(isFinished || isNotStarted) ? (
                                            <tr>
                                                <td>
                                                    {gettext('Last pause time')}
                                                    :
                                                </td>
                                                <td>
                                                    {latestPauseTime?.format(
                                                        'DD.MM.YYYY HH:mm',
                                                    )}
                                                </td>
                                            </tr>
                                        ) : null}
                                    </>
                                </TimeTrackersDurationDisplay>
                            ) : null}
                        </tbody>
                    </table>
                </div>
            )}
            {(isFinished || inExpanded) && allDownTimes.length > 0 ? (
                <table className={styles.downtimeTable}>
                    <tbody>
                        <DownTimeDisplay
                            downTimes={allDownTimes}
                            showHeader
                            warning={false}
                        />
                    </tbody>
                </table>
            ) : null}

            {!isFinished && !inExpanded ? (
                <div className={styles.timeRemainingCol}>
                    {isDowntime && latestDowntime ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>{gettext('Cause of problem')}</Tooltip>
                            }
                        >
                            <div
                                className={clsx(
                                    styles.downtimeReasons,
                                    styles.flexGrow,
                                )}
                            >
                                {latestDowntime.problems.map((p) => (
                                    <div key={p.id}>{p.name}</div>
                                ))}
                                <TimeTrackersTimeRemaining
                                    timeTrackers={timeTrackers}
                                    plannedDuration={workOrder.duration}
                                    onlyProgress
                                />
                            </div>
                        </OverlayTrigger>
                    ) : (
                        <div
                            className={clsx(styles.flexGrow, {
                                [styles.hasComment]: partProduct.comment,
                                [styles.paused]: isPaused,
                            })}
                        >
                            <TimeTrackersTimeRemaining
                                timeTrackers={timeTrackers}
                                plannedDuration={workOrder.duration}
                            />
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
};

const WorkOrderHeader = ({
    workOrder,
    partProduct,
    isExpanded,
    // Redux
    workOrderStatus,
    timeTrackers,
    newestTracker,
    oldestTracker,
    latestDowntime,
    allDownTimes,
    isNotStarted,
    isRunning,
    isFinished,
    isPaused,
    isDowntime,
    oldestStartTime,
    newestStartTime,
    latestPauseTime,

    notFinishedBeforeDeadline,
    notStartedBeforeDeadline,
    startedAfterDeadline,
}) => {
    const statusClassName = {
        [WORK_ORDER_STATUS.NOT_STARTED]: styles.notStarted,
        [WORK_ORDER_STATUS.RUNNING]: styles.running,
        [WORK_ORDER_STATUS.DONE]: styles.done,
        [WORK_ORDER_STATUS.PAUSED]: styles.paused,
        [WORK_ORDER_STATUS.DOWNTIME]: styles.downtime,
    }[workOrderStatus];

    return (
        <div className={clsx(styles.header, statusClassName)}>
            <div className={styles.infoSection}>
                <div className={styles.nameCol}>
                    {isFinished || isNotStarted ? (
                        <div className={styles.orderInfo}>
                            {partProduct.order_number} {partProduct.client.name}
                        </div>
                    ) : null}
                    <div className={styles.description}>
                        {partProduct.order_row.description}
                    </div>
                    {partProduct.comment && (
                        <div className={styles.comment}>
                            <PartProductComment>
                                {partProduct.comment}
                            </PartProductComment>
                        </div>
                    )}
                </div>
                <div className={styles.tablesSectionWrapper}>
                    {!isFinished && (
                        <div className={styles.arrowCol}>
                            {isExpanded ? (
                                <FaArrowUp
                                    className={clsx(
                                        styles.icon,
                                        styles.arrowUp,
                                    )}
                                />
                            ) : (
                                <FaArrowDown className={clsx(styles.icon)} />
                            )}
                        </div>
                    )}
                    <WorkOrderHeaderTables
                        inExpanded={false}
                        workOrder={workOrder}
                        partProduct={partProduct}
                        isExpanded={isExpanded}
                        workOrderStatus={workOrderStatus}
                        timeTrackers={timeTrackers}
                        newestTracker={newestTracker}
                        oldestTracker={oldestTracker}
                        latestDowntime={latestDowntime}
                        allDownTimes={allDownTimes}
                        isNotStarted={isNotStarted}
                        isRunning={isRunning}
                        isFinished={isFinished}
                        isPaused={isPaused}
                        isDowntime={isDowntime}
                        oldestStartTime={oldestStartTime}
                        newestStartTime={newestStartTime}
                        latestPauseTime={latestPauseTime}
                        notFinishedBeforeDeadline={notFinishedBeforeDeadline}
                        notStartedBeforeDeadline={notStartedBeforeDeadline}
                        startedAfterDeadline={startedAfterDeadline}
                    />
                </div>
            </div>
            {!isFinished && (
                <div className={clsx(styles.controlCol)}>
                    {isNotStarted || isPaused ? (
                        <StartButton
                            workOrderId={workOrder.id}
                            disabled={isRunning || isFinished || isDowntime}
                        >
                            {isPaused ? (
                                <FaPauseCircle
                                    className={clsx(styles.pause, {
                                        [styles.disabled]:
                                            isRunning ||
                                            isFinished ||
                                            isDowntime,
                                    })}
                                    aria-label={gettext('Start time tracker')}
                                />
                            ) : (
                                <FaPlayCircle
                                    className={clsx(styles.start, {
                                        [styles.disabled]:
                                            isRunning ||
                                            isFinished ||
                                            isDowntime,
                                    })}
                                    aria-label={gettext('Start time tracker')}
                                />
                            )}
                        </StartButton>
                    ) : null}
                    {!isPaused && !isNotStarted ? (
                        <PauseButton
                            latestTrackerId={newestTracker?.id}
                            disabled={!isRunning}
                        >
                            <FaPauseCircle
                                className={clsx(styles.pause, {
                                    [styles.disabled]: !isRunning,
                                })}
                                aria-label={gettext('Pause time tracker')}
                            />
                        </PauseButton>
                    ) : null}
                    {isDowntime && !isFinished ? (
                        <SolveDowntimeButton
                            workOrderId={workOrder.id}
                            disabled={!isDowntime}
                        >
                            <FaExclamationCircle
                                className={clsx(styles.downtime, {
                                    [styles.disabled]: !isDowntime,
                                })}
                                aria-label={gettext('Mark as solved')}
                            />
                        </SolveDowntimeButton>
                    ) : (
                        <AffixDowntimeButton
                            workOrderId={workOrder.id}
                            disabled={!isRunning}
                        >
                            <FaExclamationCircle
                                className={clsx(styles.downtime, {
                                    [styles.disabled]: !isRunning,
                                })}
                                aria-label={gettext('Activate downtime')}
                            />
                        </AffixDowntimeButton>
                    )}
                    <StopButton
                        latestTrackerId={newestTracker?.id}
                        disabled={!isRunning}
                    >
                        <FaCheckCircle
                            className={clsx(styles.stop, {
                                [styles.disabled]: !isRunning,
                            })}
                            aria-label={gettext('Stop time tracker')}
                        />
                    </StopButton>
                </div>
            )}
        </div>
    );
};

const WorkOrderHeaderComponentsPropTypes = {
    workOrder: workOrderDenormalizedPropType.isRequired,
    workOrderStatus: PropTypes.oneOf(Object.values(WORK_ORDER_STATUS))
        .isRequired,
    partProduct: PartProductPropType.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    timeTrackers: PropTypes.arrayOf(timeTrackerPropType).isRequired,
    newestTracker: timeTrackerPropType,
    oldestTracker: timeTrackerPropType,
    latestDowntime: downtimePropType,
    allDownTimes: PropTypes.arrayOf(downtimePropType).isRequired,
    isNotStarted: PropTypes.bool.isRequired,
    isRunning: PropTypes.bool.isRequired,
    isFinished: PropTypes.bool.isRequired,
    isPaused: PropTypes.bool.isRequired,
    isDowntime: PropTypes.bool.isRequired,
    oldestStartTime: momentPropTypes.momentObj,
    newestStartTime: momentPropTypes.momentObj,
    latestPauseTime: momentPropTypes.momentObj,

    notFinishedBeforeDeadline: PropTypes.bool.isRequired,
    notStartedBeforeDeadline: PropTypes.bool.isRequired,
    startedAfterDeadline: PropTypes.bool.isRequired,
};
WorkOrderHeaderTables.propTypes = {
    inExpanded: PropTypes.bool.isRequired,
    ...WorkOrderHeaderComponentsPropTypes,
};

WorkOrderHeader.propTypes = WorkOrderHeaderComponentsPropTypes;

const mapStateToProps = (state, ownProps) => {
    const workOrderStatus = workOrdersSelectors.getWorkOrderStatus(
        state,
        ownProps.workOrder.id,
    );
    const now = moment();
    const isNotStarted = workOrderStatus === WORK_ORDER_STATUS.NOT_STARTED;
    const isRunning = workOrderStatus === WORK_ORDER_STATUS.RUNNING;
    const isFinished = workOrderStatus === WORK_ORDER_STATUS.DONE;
    const isPaused = workOrderStatus === WORK_ORDER_STATUS.PAUSED;
    const isDowntime = workOrderStatus === WORK_ORDER_STATUS.DOWNTIME;

    const newestTracker = timeTrackersSelectors.getNewestTracker(
        state,
        ownProps.workOrder.id,
    );
    const oldestTracker = timeTrackersSelectors.getOldestTracker(
        state,
        ownProps.workOrder.id,
    );
    const latestPauseTime =
        newestTracker && newestTracker.end_time
            ? moment(newestTracker.end_time)
            : undefined;

    const oldestStartTime = oldestTracker
        ? moment(oldestTracker.start_time)
        : undefined;

    const notFinishedBeforeDeadline = isFinished
        ? latestPauseTime > moment(ownProps.workOrder.end_time)
        : now > moment(ownProps.workOrder.end_time);
    const startedAfterDeadline =
        oldestStartTime > moment(ownProps.workOrder.start_time);
    const notStartedBeforeDeadline =
        (oldestStartTime || now) > moment(ownProps.workOrder.end_time);

    return {
        workOrderStatus,
        isNotStarted,
        isRunning,
        isFinished,
        isPaused,
        isDowntime,

        timeTrackers: timeTrackersSelectors.getListByWorkOrderId(
            state,
            ownProps.workOrder.id,
        ),
        newestTracker,
        oldestTracker,
        latestDowntime: downtimesSelectors.getByWorkOrderId(
            state,
            ownProps.workOrder.id,
        ),
        allDownTimes: downtimesSelectors.getAllByWorkOrderId(
            state,
            ownProps.workOrder.id,
        ),
        oldestStartTime,
        newestStartTime: newestTracker
            ? moment(newestTracker.start_time)
            : undefined,
        latestPauseTime,
        notFinishedBeforeDeadline,
        notStartedBeforeDeadline,
        startedAfterDeadline,
    };
};

export default connect(mapStateToProps, null)(WorkOrderHeader);

export const WorkOrderHeaderTablesConnected = connect(
    mapStateToProps,
    null,
)(WorkOrderHeaderTables);
